import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {isMobileOnly, isTablet} from "react-device-detect";
import parse from "react-html-parser";

const GuideSingleTwo = ({
      guideArticle,
      currency,
      sliderClassName,
      spaceBottomClass,
      colorClass,
      titlePriceClass, isSide, index
    }) => {

  return (
      <Fragment>
        <div
            className={isSide ? `col-xl-3 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }` : `col-xl-2 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }`}
            style={{width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}

        >
          <div
              style={{width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}
              className={`sales-wrap-2 ${
                  spaceBottomClass ? spaceBottomClass : ""
              } ${colorClass ? colorClass : ""} `}
          >
            <div className="product-img" style={{backgroundColor: "white", width: isMobileOnly ? "120px !important" : "230px !important", textAlign: "center", height: isMobileOnly ? "120px !important" : "230px !important", overflow: "hidden"}}>
              <a href={guideArticle.link} target="_blank">
                <div style={{
                  height: '100%',
                  margin: '0',
                  background: `url(${guideArticle._embedded && guideArticle._embedded['wp:featuredmedia'] && guideArticle._embedded['wp:featuredmedia']['0'] 
                    && guideArticle._embedded['wp:featuredmedia']['0'].source_url  != "" ? guideArticle._embedded['wp:featuredmedia']['0'].source_url : ""
                        }) center fixed no-repeat`,
                  backgroundSize: 'cover',
                }}>


                </div>
              </a>

            </div>
            <div className="product-content-2">
              <div
                  className={`title-price-wrap-2 ${
                      titlePriceClass ? titlePriceClass : ""
                  }`}
              >
                <div className="price-2">
                  <h3 style={{lineHeight: "13px", fontSize: "16px"}}>
                  <a href={guideArticle.link} target="_blank">
                      {guideArticle.title && guideArticle.title.rendered ? parse(guideArticle.title.rendered) : ""}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
  );
};

GuideSingleTwo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  guideArticle: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default GuideSingleTwo;
